.title_hd strong {
  font-weight: 800;
}

.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  bottom: 110%;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.devtext strong {
  font-weight: 600;
}
.hovercolor .newtext strong {
  font-weight: 600;
  color: #00102b;
}
.hovercolor:hover .newtext strong {
  color: #fff;
}
.trusted-img.slick-initialized .slick-slide > div {
  margin-bottom: 0px !important;
}

/* .toplabelhover textarea:focus::-webkit-input-placeholder, */
.toplabelhover input:focus::-webkit-input-placeholder {
  font-size: 0.75em;
  position: relative;
  top: -18px;
  transition: 0.2s ease-out;
}
/* .toplabelhover textarea::-webkit-input-placeholder, */
.toplabelhover input::-webkit-input-placeholder {
  transition: 0.2s ease-in;
}
/* .toplabelhover textarea:focus, */
.toplabelhover input[type='email']:focus,
.toplabelhover input[type='text']:focus,
.toplabelhover input[type='password']:focus {
  height: 45px;
  padding-bottom: 0px;
  transition: 0.2s ease-in;
}
/* .toplabelhover textarea:focus, */
.toplabelhover input[type='email'],
.toplabelhover input[type='text'],
.toplabelhover input[type='password'] {
  height: 45px;
  transition: 0.2s ease;
}
